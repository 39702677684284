import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet} from 'react-router-dom'

const AdminVerify = () => {
    const {user,isAuthenticated,isLoading}=useSelector((state)=>state.admin);
    console.log("user",user,isAuthenticated)

    if(isLoading){
        return <h1>Loading..</h1>
    }

    // if(!user || user.length===0){
    //     return <Navigate to={'/'} />
    // }

    return (
        <Outlet />
    )
}

export default AdminVerify;