import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { RegisterUser } from '../feature/AuthFeature';
import WDCLogo from "../assests/register/WDClogo.png";
import WDALogo from "../assests/register/WDAlogo.png";

const USER_REGEX = /^[A-z][A-z0-9-_ ]{3,23}$/;
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const MOBILE_REGEX = /^[0-9]{10}$/;
const DOB_REGEX = /^\d{4}-\d{2}-\d{2}$/;
const GENDER_REGEX = /^(?:male|female|other)$/;
const ORGANIZATION_REGEX = /^[A-z][A-z0-9-_ ]{3,68}$/;

const Register = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = new URLSearchParams(window.location.search);
  const roles = params.get("role");

  const userRef = useRef();

  const [role, setRole] = useState("");
  const [type, setType] = useState("");


  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);


  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);


  const [mobileNumber, setMobileNumber] = useState("");
  const [validMobileNumber, setValidMobileNumber] = useState(false);
  const [mobileFocus, setMobileFocus] = useState(false);



  const [dob, setDob] = useState("");
  const [validDob, setValidDob] = useState(false);
  const [dobFocus, setDobFocus] = useState(false);

  console.log(dob)



  const [gender, setGender] = useState("");
  const [validgender, setValidgender] = useState(false);
  const [genderFocus, setGenderFocus] = useState(false);


  const [organization, setOrganization] = useState("");
  const [validOrganization, setValidOrganization] = useState(false);
  const [organizationFocus, setOrganizationFocus] = useState(false);

  const [image, setImage] = useState("");
  const [validImage, setValidImage] = useState(false);
  const [imageFocus, setImageFocus] = useState(false);

  const [modal,setModal]=useState(false);

  const [ug, setUg] = useState({ year: "" });



  const [pg, setPg] = useState([
    {
      qualification: "",
      specialization: "",
      designation: "",
    },
  ]);
  const [accompany, setAccompany] = useState([{ name: "", email: "" }]);

    const handleAvatar = (e) => {
    const file = Array.from(e.target.files)[0];
    setImage(file);
   
  };
 useEffect(() => {
   if(image?.size <= 2*1024*1024){
     setValidImage(true);
   }
   else{
     setValidImage(false);
   }
 },[image]);

  const dispatch = useDispatch();

  useEffect(() => {
    setRole(roles);
  }, [roles]);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setValidName(USER_REGEX.test(name));
  }, [name]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidMobileNumber(MOBILE_REGEX.test(mobileNumber));
  }, [mobileNumber]);


  useEffect(() => {
    setValidDob(DOB_REGEX.test(dob));
  }, [dob])


  useEffect(() => {
    setValidgender(GENDER_REGEX.test(gender));
  }, [gender])

  useEffect(() => {
    setValidOrganization(ORGANIZATION_REGEX.test(organization));
  }, [organization]);


  useEffect(() => {
    const date = new Date();

    if (date < new Date("2025-04-01")) {
      setType(1);
    } else if (date >= new Date("2025-04-01") && date <= new Date("2025-08-31")) {
      setType(2);
    } else if (date > new Date("2025-09-01") && date <= new Date("2025-09-31")) {
      setType(3);
    } 
  }, []);


  const handleAccompanyChange = (index, field, value) => {
    const updatedAccompany = [...accompany];
    updatedAccompany[index][field] = value;
    setAccompany(updatedAccompany);
  };

  // Add a new row
  const handleAddRow = () => {
    setAccompany([...accompany, { name: "", email: "" }]);
  };

  // Remove a specific row
  const handleRemoveRow = (index) => {
    const updatedAccompany = accompany.filter((_, i) => i !== index);
    setAccompany(updatedAccompany);
  };

  console.log("accompany", accompany)

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      console.log("Razorpay script loaded");
    };
    script.onerror = () => {
      console.error("Failed to load Razorpay script");
    };
    document.body.appendChild(script);
  }, []);

  const initPayment = (data) => {
    if (typeof window.Razorpay === "undefined") {
      console.error("Razorpay library is not loaded.");
      return;
    }

    const options = {
      key: "rzp_test_DvO86UhuANFt5u",
      amount: data.TotalAmounts,
      currency: data.currency,
      name: "World Dental Conference - 2024",
      description: "Conference Registration",
      order_id: data.order.id,
      handler: async (response) => {
        console.log("response", response);
        const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;

        try {
          const verifyUrl = `http://localhost:4000/api/v1/registration/verify`;
          const result = await axios.post(verifyUrl, {
            razorpay_payment_id,
            razorpay_order_id,
            razorpay_signature,
          });
          console.log(result.data);

          if (result.data.success) {
            alert("Payment successful. Please check your email for the invoice.");
            window.location.reload();
          }
        } catch (error) {
          console.error(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };

    const razorpayObjects = new window.Razorpay(options);
    razorpayObjects.open();
  };

  const handlePgChange = (field, value) => {
    setPg((prevPg) => ({
      ...prevPg,
      [field]: value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

        if(!validName || !validEmail || !validMobileNumber || !validDob || !validgender || !validOrganization || !validImage){
          return;
        }

    const object = { name, email, mobileNumber, dob, gender, organization, ug, pg, image, role, type, accompany };

    dispatch(RegisterUser(object)).then(unwrapResult).then((res) => {
      console.log(res);
      initPayment(res);
    }).catch((err) => {
      console.log(err);
    })

  }

  const handleInputChange = (e) => {
    const { value } = e.target;
    setUg((prevUg) => ({ ...prevUg, year: value }));
  };

  const handleAccompanyPersonChange=(e)=>{
     if(e.target.checked){
       setModal(true);
     }
     else{
      setModal(false);
     }
  }

  return (
    <>

      <div className="headedImage">
        <div className="container-fluid col-10">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-3 col-md-12 col-12">
              <img
                src={WDCLogo}
                className="img-fluid register_WDCLogo center-block d-block mx-auto"
                  alt='logo'
              />
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-5 mt-lg-0">
              <p className="para_worldDental text-center">
                World Dental Conference 2024
              </p>
              <h2 className="head_worldDental text-center">REGISTRATION</h2>
            </div>
            <div className="col-lg-3 col-md-12 col-12 mt-5 mt-lg-0">
              <img
                src={WDALogo}
                className="img-fluid register_WDCLogo center-block d-block mx-auto"
                alt='logo'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <form className='row' onSubmit={handleSubmit}>

          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='text' value={name} className=' input_register' ref={userRef} autoComplete="off" onChange={(e) => setName(e.target.value)} placeholder='Enter Your Name' required
                aria-invalid={validName ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setUserFocus(true)}
                onBlur={() => setUserFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={name && !validName ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter Valid Name
                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='text' value={email} className=' input_register' autoComplete="off" onChange={(e) => setEmail(e.target.value)} placeholder='Enter Your Email' required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={email && !validEmail ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter Valid Email
                </p>
              </div>
            </div>
          </div>


          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='text' value={mobileNumber} className=' input_register' autoComplete="off" onChange={(e) => setMobileNumber(e.target.value)} placeholder='Enter Your Mobile Number' required
                aria-invalid={validMobileNumber ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setMobileFocus(true)}
                onBlur={() => setMobileFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={mobileNumber && !validMobileNumber ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter 10 Digit Valid Mobile Number
                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
            <label className='input_label'>Date of Birth</label>
              <input type='date' value={dob} className=' input_register' autoComplete="off" onChange={(e) => setDob(e.target.value)} placeholder='Enter Your Date Of Birth' required
                aria-invalid={validDob ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setDobFocus(true)}
                onBlur={() => setDobFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={dob && !validDob ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter Valid Date of Birth
                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <select value={gender} className=' input_register' onChange={(e) => setGender(e.target.value)} placeholder='Enter Your Gender' required autoComplete='off' aria-invalid={validgender ? "false" : "true"} aria-describedby="uidnote"
                onFocus={() => setGenderFocus(true)}
                onBlur={() => setGenderFocus(false)}>
                <option value={""}>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={gender && !validgender ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter Valid Gender
                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='text' value={organization} className=' input_register' autoComplete="off" onChange={(e) => setOrganization(e.target.value)} placeholder={role === "ug" ? "Enter Your College Name" : "Clinic Name / College Name / Hospital Name / Others"} required
                aria-invalid={organization ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setOrganizationFocus(true)}
                onBlur={() => setOrganizationFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={organization && !validOrganization ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  {role === "ug" ? "Please Enter Your College Name" : " Please Enter Your Clinic Name / College Name / Hospital Name / Others"}
                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='file' placeholder='Image' className='image_borderRegister' onChange={handleAvatar} autoComplete="off" accept="image/*" aria-invalid={organization ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setImageFocus(true)}
                onBlur={() => setImageFocus(false)} required />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={image && !validImage ? "text-danger error_text my-2 instruction" : "offscreen"}>
                 Please choose an image.Image size exceeds the maximum limit of 5 MB.
                </p>
              </div>
            </div>
          </div>

          {role === "ug" && (

            <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
             <select value={ug.year} onChange={handleInputChange} className=' input_register' required>
              <option value={""}>Select Year</option>
              <option value="First Year">First Year</option>
              <option value="Second Year">Second Year</option>
              <option value="Third Year">Third Year</option>
              <option value="Final Year">Final Year</option>
              <option value="House Surgeon">House Surgeon</option>
            </select>
            </div>
          </div>
           
          )}<br />

          {role === "pg" && (
            <>
             <div className='col-lg-6 col-12 mb-4  d-flex justify-content-lg-end align-items-lg-end justify-content-center'>
            <div className=''>
               <select
                value={pg.qualification}
                onChange={(e) => handlePgChange("qualification", e.target.value)}  className=' input_register1  ' required
              >
                <option value={""}>Select Qualification</option>
                <option value="BDS">BDS</option>
                <option value="MDS">MDS</option>
              </select>
            </div>
            </div>

            <div className='col-lg-6 col-12 mb-4 justify-content-center align-items-center' >
            <div className='input_main  '>
               <select
                value={pg.designation}
                onChange={(e) => handlePgChange("designation", e.target.value)}
                className=' input_register1  '
              >
                <option value={""}>Select Designation</option>
                <option value="Post Graduate Fellow">Post Graduate Fellow</option>
                <option value="Assistant Professor">Assistant Professor</option>
                <option value="Associate Professor">Associate Professor</option>
                <option value="Professor">Professor</option>
                <option value="Head of Department">Head of Department</option>
                <option value="Principal/Dean">Principal/Dean</option>
                <option value="Private Practitioner">Private Practitioner</option>
                <option value="Consultant">Consultant</option>
              </select>
            </div>
            
          </div>

            <div className='col-lg-6 col-12 mb-4  d-flex justify-content-lg-end align-items-lg-end justify-content-center'>
            <div className='  '>
             <select
                value={pg.specialization}
                onChange={(e) => handlePgChange("specialization", e.target.value)}
                className=' input_register1  '
              >
                <option value={""}>Select Specialization</option>
                <option value="Oral Medicine & Radiology">Oral Medicine & Radiology</option>
                <option value="Oral Pathology">Oral Pathology</option>
                <option value="Public Health Dentistry">Public Health Dentistry</option>
                <option value="Orthodontics">Orthodontics</option>
                <option value="Endodontics">Endodontics</option>
                <option value="Periodontics">Periodontics</option>
                <option value="Pedodontics">Pedodontics</option>
                <option value="Prosthodontics">Prosthodontics</option>
                <option value="Oral & Maxillofacial Surgery">Oral & Maxillofacial Surgery</option>
              </select>
            </div>
            
          </div>
             </>
          )}

          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto d-flex justify-content-lg-start align-items-center justify-content-center'>
<input type='checkbox' onChange={handleAccompanyPersonChange} className='' />
<label className='input_label mx-2'>Accompany Person:-</label>

            </div>
          </div>

          
           {modal && (
             <>
    {accompany.map((entry, index) => (
            <div key={index}>
            <div className='row'>
            <div className='col-lg-6 col-12 mb-4  d-flex justify-content-lg-end align-items-lg-end justify-content-center'>
            <div className=''>
   <input type='text' value={entry.name}  className=' input_register1  ' onChange={(e) => handleAccompanyChange(index, "name", e.target.value)} placeholder='Enter Name' />
            </div>
            </div>
                  <div className='col-lg-6 col-12 mb-4 d-flex justify-content-center align-items-center justify-content-lg-start'>
              <input type='email'  className=' input_register1  ' value={entry.email} onChange={(e) => handleAccompanyChange(index, "email", e.target.value)} placeholder='Enter email' />
              </div>
            
            </div>
            <div className='row'>
            <div className='col-lg-6 col-6 mb-4  d-flex justify-content-end align-items-end'>
            <div className=''>  
                <button className='btn btn-danger btn_width' type='button' onClick={() => handleRemoveRow(index)} disabled={accompany.length === 1}>
                Remove
              </button>  
            </div>
            </div>

            <div className='col-lg-6 col-6 mb-4'>
            <div className=''>
                <button className='btn btn-success btn_width' type='button' onClick={() => handleAddRow(index)} >
                &nbsp;&nbsp;Add&nbsp;&nbsp;
              </button>  
            </div>

            </div>
            </div>
            </div>
          ))}
             </>
           )}  
      


             <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
          <button type='submit' className='register_submitbtn'>Submit</button>
</div>


          </div>
        </form>
      </div>

    </>
  )
}

export default Register