import React, { useEffect } from "react";
import Registration from "../assests/Home/Rectangle12.png";
import Abstract from "../assests/Home/Rectangle8.png";
import Dental from "../assests/Home/Rectangle11.png";
import About from "../assests/Home/about.jpg";
import Chairman from "../assests/Home/chairman.jpeg";
import Attend from "../assests/Home/why.jpg";
import Letter from "../assests/Home/Letter.jpg";
import certificate from "../assests/Home/certificate.jpg";
import pastImg1 from "../assests/Home/pastPresident1.jpg";
import pastImg2 from "../assests/Home/pastPresident2.jpg";

import { Link } from "react-router-dom";
import logo from "../assests/contact/logo.png";
import logos from "../assests/contact/logo1.png";
import company1 from "../assests/contact/company1.png";
import company2 from "../assests/contact/company2.png";
import company3 from "../assests/contact/company3.png";
import textImg from "../assests/contact/textImg.png";

const Home = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className="center">
      <div className="home_banner pb-5">
        <div className="container-fluid col-lg-12 col-12">
          <div className="row col-lg-10 col-md-10 col-12 mx-auto mt-5">
            <div className="col-lg-8 col-md-10 col-12 d-flex align-items-center justify-content-between">
              <img
                src={logo}
                className="img-fluid contact_logos center-block d-block"
                alt="logo"
              />
              <img
                src={logos}
                className="img-fluid company_logo2 right-block d-block "
                alt="logo"
              />
            </div>
          </div>
          <div className="row d-flex justify-content-lg-end justify-content-center my-lg-0 my-5">
            <div className="col-lg-4 col-md-10 col-12 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center flex-column">
                <span className="companyText">PLATINUM SPONSOR</span>
                <img
                  src={company1}
                  className="img-fluid company_logo "
                  alt="logo"
                />
              </div>
              <div className="d-flex align-items-center justify-content-center flex-column my-md-0 my-4">
                <span className="companyText">GOLD SPONSOR</span>
                <img
                  src={company2}
                  className="img-fluid company_logo2"
                  alt="logo"
                />
              </div>
              <div className="d-flex align-items-center justify-content-center flex-column">
                <span className="companyText">KNOWLEDGE SPONSOR</span>
                <img
                  src={company3}
                  className="img-fluid company_logo2"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="row col-12 mx-auto d-flex align-items-end justify-content-between">
            <div className="col-lg-8 col-md-12 col-12 d-flex align-items-center justify-content-between flex-column">
              <div className="col-md-10 col-12 d-flex align-items-center justify-content-center flex-column">
                <img
                  src={textImg}
                  className="img-fluid textImg center-block d-block mx-lg-0 mx-auto "
                  alt="logo"
                />
                <span className="singapore_para">Singapore 2025</span>
              </div>
            </div>
            <div className="col-lg-4 col-12 text-md-center text-center">
              <span className="date_para">
                September 30 <sup>th</sup> & October 01<sup>st</sup>
              </span>
              <br />
              <span className="date_para">(Tuesday & Wednesday)</span>
              <br />
              <span className="hidden">Bintang Bali Resort</span>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid col-10">
        <div className="row mt-5">
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <Link to="/registerDetail" className="linkALL">
              <div className="Registration_img">
                <img
                  src={Registration}
                  className="img-fluid rectangle_images"
                  alt="logo"
                />
                <h1 className="registration_head text-center">Registration</h1>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <Link to="/abstractinstruction" className="linkALL">
              <div className="Registration_img">
                <img
                  src={Abstract}
                  className="img-fluid rectangle_images"
                  alt="logo"
                />
                <h1 className="registration_head text-center">
                  Abstract Submission{" "}
                </h1>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <Link to="/award" className="linkALL">
              <div className="Registration_img">
                <img
                  src={Dental}
                  className="img-fluid rectangle_images"
                  alt="logo"
                />
                <h1 className="registration_head text-center">
                  Apply For Dental Awards
                </h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="row mt-5 d-flex justify-content-center">
        <div className="col-lg-11 d-flex justify-content-center align-items-center flex-column-reverse flex-lg-row">
          <div className="mt-5 col-lg-6 col-md-12 col-12">
            <img
              src={About}
              className="img-fluid d-block center-block mx-auto about_images"
              alt="logo"
            />
          </div>
          <div className="col-lg-6 col-12  p-lg-0 p-5">
            <h3 className="about_head">
              WELCOME TO THE WORLD DENTAL CONFERENCE 2025
            </h3>
            <p className="about_para mt-4">
              The goal of the WDC 2025 is to present the latest and the best in
              scientific research and clinic cases in all specialties in
              Dentistry. It will also provide an international dias for dentists
              from all over the world to exchange ideas, knowledge and encourage
              networking with similarspecialists.
            </p>
            <p className="about_para mt-4">
              World-renowned experts from different countries will present Guest
              Lectures, and Keynote Lectures on a wide range of topics that are
              of particular interest to the dental speciality.
            </p>
            <p className="about_para mt-4">
              We also have a special presentation category, Rising Star
              fordentists who have less than five years of experience after
              completion of Post Graduation. In addition to this there will be a
              competitive paper and poster presentation open to dental students
              (Under Graduates, Post Graduates).
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-5 d-flex justify-content-center">
        <div className="col-lg-11 d-flex justify-content-center align-items-center flex-column flex-lg-row">
          <div className="col-lg-6 col-12 col-md-12">
            <h3 className="chairman_head">ORGANISING CHAIRMAN MESSAGE</h3>
            <p className="chairman_para mt-4">
              <span className="boldHeading">Dear Esteemed Colleagues,</span>
              <br />
              <br />
              It is with great pleasure that I invite you to Singapore for the
              World Dental Conference 2025, taking place from September 30 to
              October 1, 2025. This prestigious event will bring together
              leading dental professionals, researchers, and industry experts
              from around the globe to exchange ideas, share groundbreaking
              research, and explore the future of dentistry.Singapore, known for
              its world-class infrastructure, innovationdriven healthcare
              system, and vibrant culture, provides the perfect backdrop for
              this global gathering. Beyond the conference halls, you will have
              the opportunity to experience the city’s stunning landmarks,
              diverse cuisine, and rich heritage.
              <br />
              <br />
              This year's theme, 'Embracing Innovations in Dentistry,' reflects
              our commitment to staying at the forefront of advancements in
              dentistry. WDC 2025 promises insightful keynote sessions,
              groundbreaking research presentations, and thought-provoking
              discussions. It promises to be an unforgettable experience,
              bringing together the global dental community to share knowledge,
              innovations and best practices. In addition to scientific
              interactions, as always, this WDC also promises you an
              unforgettable experience of fun and entertainment. This is why WDC
              today, is the most awaited International Dental Conference of
              2025.
              <br />
              <br />
              Forthe first time, in WDC2025, we will also be arranging a
              citytourpackage ofSingapore for the delegates after the
              conference. This will allow all delegates to travel together and
              interact better and feel much more comfortable within a known
              crowd.
              <br />
              <br />
              The Excellence in Dentistry Awards, which recognizing and
              acknowledging dentists for their achievements and for maintaining
              high standards of excellence, will also feature in the years
              conference and will be bigger and better than any of the WDC’s.
              <br />
              <br />I warmly invite you to join us in Singapore for an enriching
              and inspiring experience. Let’s come together to learn, innovate,
              and transform the future of dentistry and have a lot of fun too.
            </p>
          </div>
          <div className="mt-5 col-lg-6 col-md-12 col-12">
            <img
              src={Chairman}
              className="img-fluid chairman_images"
              alt="logo"
            />
          </div>
        </div>
      </div>
      <div className="row mt-5 d-flex justify-content-center align-items-center ">
        <div className="col-lg-10 d-flex justify-content-center align-items-center flex-column-reverse flex-lg-row">
          <div className="mt-5 col-lg-6 col-12">
            <img
              src={Attend}
              className="img-fluid mx-auto about_images"
              alt="logo"
            />
          </div>
          <div className="mt-5 col-lg-6 col-md-12 co-12 ml-5 ml-lg-0">
            <h3 className="about_head ">
              WHY ATTEND THE WORLD DENTAL CONFERENCE 2025
            </h3>
            <ul className="mt-4">
              <li className="attendPara">
                The WDC 2025 will keep you abreast with all the latest research
                developments and treatment techniques in Dentistry.
              </li>
              <li className="attendPara mt-3">
                The WDC 2025 will expose you to some of the best keynote
                speakers in dentistry from around the world.
              </li>
              <li className="attendPara mt-3">
                You will receive Information about the latest equipment and
                dental materials from traders at various stalls.
              </li>
              <li className="attendPara mt-3">
                The most economical international dental conference in recent
                times
              </li>
              <li className="attendPara mt-3">
                The WDC 2025 will serve as the best platform to network and
                socialise with like-minded professionals.
              </li>
              <li className="attendPara mt-3">
                Special Awards Night is conducted to recognize and acknowledge
                outstanding contribution by dentists all over the world.
              </li>
              <li className="attendPara mt-3">
                Special free paper and poster sessions for Undergraduate
                Students, Dentists and Post Graduate Students.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mt-5 d-flex justify-content-center align-items-center ">
        <div className="col-lg-10 d-flex justify-content-between align-items-center flex-column-reverse flex-lg-row">
          <div className="mt-5 col-lg-6 co-12">
            <p
              className="boldHeading mb-3 text-center"
              style={{
                textTransform: "Uppercase",
              }}
            >
              Letter of participation from Indonesian Associarion of
              Aesthetic Dentistry.
            </p>
            <img src={Letter} className="img-fluid mx-auto" alt="logo" />
          </div>
          <div className="mt-5 col-lg-6 col-12">
            <div className="col-11 mx-auto">
              <p
                className="boldHeading mb-3 text-center"
                style={{
                  textTransform: "Uppercase",
                }}
              >
                Official praises and appreciation message from the President of
                the Indonesian Dental Association, partners of World
                Dental Conference 2024
              </p>
              <img
                src={certificate}
                className="img-fluid d-block mx-auto about_images"
                alt="logo"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 d-flex justify-content-center align-items-center ">
        <div className="col-10 d-flex justify-content-center">
          <p className="boldHeading" style={{ textTransform: "Uppercase" }}>
            Letter of appreciation from the past President of the Dental Council
            of India , Dr. Dibyendu Mazumder.
          </p>
        </div>
        <div className="col-10 d-flex justify-content-center align-items-center flex-column-reverse flex-lg-row">
          <div className="mt-5 col-lg-6 col-12">
            <img
              src={pastImg2}
              className="img-fluid mx-auto about_images"
              alt="logo"
            />
          </div>
          <div className="mt-5 col-lg-6 col-12">
            <img
              src={pastImg1}
              className="img-fluid mx-auto about_images"
              alt="logo"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid col-lg-10 col-12 mt-5">
        <div className="row">
          <div className="col-lg-12 col-12">
            <h3 className="about_head text-center mt-5">TESTIMONIALS</h3>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-4 col-12">
            <video
              width="320"
              height="240"
              controls
              className="center-block d-block mx-auto"
            >
              <source src="./faceBook/Dr. Susmita.mp4" type="video/mp4" />
            </video>
            <p className="text-center mt-2">
              <b>
                Dr. Susmita Saxana <br />
                Past President of the Indian Association of Oral And Maxillo
                Facial Pathology.
              </b>
            </p>
          </div>

          <div className="col-lg-4 col-12">
            <video
              width="320"
              height="240"
              controls
              className="center-block d-block mx-auto"
            >
              <source
                src="./faceBook/President of Dental Council of India ,.mp4"
                type="video/mp4"
              />
            </video>
            <p className="text-center mt-2">
              <b>President of Dental Council of India</b>
            </p>
          </div>

          <div className="col-lg-4 col-12">
            <video
              width="320"
              height="240"
              controls
              className="center-block d-block mx-auto"
            >
              <source
                src="./youtube/Dr Wong Foot Lecture Preview.mp4"
                type="video/mp4"
              />
            </video>
            <p className="text-center mt-2">
              <b>
                Dr Wong <br />
                Foot Lecture Preview
              </b>
            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-4 col-12">
            <video
              width="320"
              height="240"
              controls
              className="center-block d-block mx-auto"
            >
              <source
                src="./youtube/Dr. Abdul H. Kawaiah from Netherlands.mp4"
                type="video/mp4"
              />
            </video>
            <p className="text-center mt-2">
              <b>
                Dr. Abdul H. Kawaiah <br />
                Netherlands
              </b>
            </p>
          </div>

          <div className="col-lg-4 col-12">
            <video
              width="320"
              height="240"
              controls
              className="center-block d-block mx-auto"
            >
              <source
                src="./youtube/Dr. Gulcan Sahin, Turkey, Lecture Preview.mp4"
                type="video/mp4"
              />
            </video>
            <p className="text-center mt-2">
              <b>
                Dr. Gulcan Sahin <br />
                Turkey, Lecture Preview
              </b>
            </p>
          </div>

          <div className="col-lg-4 col-12">
            <video
              width="320"
              height="240"
              controls
              className="center-block d-block mx-auto"
            >
              <source
                src="./youtube/Dr. Kleber Meireles, Brazil, Lecture preview.mp4"
                type="video/mp4"
              />
            </video>
            <p className="text-center mt-2">
              <b>
                Dr. Kleber Meireles <br />
                Brazil, Lecture preview
              </b>
            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6 col-12">
            <video
              width="100%"
              height="420"
              controls
              className="center-block d-block mx-auto"
            >
              <source src="./faceBook/facebook_video.mp4" type="video/mp4" />
            </video>
            <p className="text-center mt-2">
              <b>
                WORLD DENTAL CONFERENCE 2023 <br />
                BANGKOK, THAILAND. <br />
                SEPTEMBER 30th & October 1st
              </b>
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <video
              width="100%"
              height="420"
              controls
              className="center-block d-block mx-auto"
            >
              <source
                src="./youtube/World Dental Conference 2019 Malaysia.mp4"
                type="video/mp4"
              />
            </video>
            <p className="text-center mt-2">
              <b>
                WORLD DENTAL CONFERENCE 2019 <br />
                MALAYSIA
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
