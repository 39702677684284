import React, {  useEffect, useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux';
import { AdminLogin } from '../feature/AdminFeature';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import worldImg from "../assests/contact/textImg.png";
import logo from "../assests/contact/logo.png";
import logos from "../assests/contact/logo1.png";
import date from "../assests/contact/date.png";
const AdminAuthLogin = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [errors, setErrors] = useState(false);
    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");


    const dispatch=useDispatch()
    const navigate = useNavigate()
    const {user}=useSelector((state)=>state.admin);
    console.log("admin",user)

    const handleSubmit=(e)=>{
        e.preventDefault();
       try {
         const object={email,password}
         dispatch(AdminLogin(object)).then(unwrapResult).then(res=>(
           navigate("/adminDetails")
         )).catch(err=>(
           setErrors(true)
         ))
       } catch (error) {
        console.log(error);
        
       }
    }


  return (
    <div className="row login d-flex justify-content-center align-items-center">
    <div className="col-lg-6 col-12 login_mobile_tab">
      <div className="container-fluid col-10">
        <div className="row d-flex align-items-center">
        <div className="col-lg-4 col-md-6 col-6 ">
            <img
              src={logo}
              className="img-fluid contact_logos mt-5 center-block d-block mx-lg-0 mx-auto "
            />
          </div>
          <div className="col-lg-3 col-md-3 col-3"></div>
          <div className="col-lg-4 col-md-3 col-3">
            <h3 className="organizede">
              {" "}
              <span style={{ visibility: "hidden" }}>s</span>
            </h3>
            <img
              src={logos}
              className="img-fluid contact_logos mt-5 center-block d-block mx-lg-0 mx-auto"
            />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-12 col-md-12 col-12 mt-4">
            <img
              src={worldImg}
              className="img-fluid center-block d-block mx-auto world_data"
            />
            <h2 className="mt-4 bangok_heads">Singapore 2025</h2>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-6 col-12 Login_background">
      <div>
        <div>
          <h2 className="login_head">Login</h2>
          <div className="d-flex mt-5">
          </div>
          <p className="login_para mt-5">
            Welcome to the World Dentist Conference login page. Please enter
            your login credentials to access your account:
          </p>
        </div>

        <form className="loginForm" onSubmit={handleSubmit}>
          <div className="mt-5">
            <input
              className="loginInput"
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              type="text"
              placeholder="Enter your email..."
            />
          </div>

          <div className="mt-5 d-flex">
            <input
              className="loginInput"
              type={passwordType}
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              placeholder="Enter your password..."
            />
            <div onClick={togglePassword} className="showbar_icon">
              {passwordType === "password" ? (
                <i className="bi bi-eye-slash"></i>
              ) : (
                <i className="bi bi-eye"></i>
              )}
            </div>
          </div>
          <button
            className="loginButton mt-5"
            type="submit"
          >
            Login
          </button>
        </form>
        
        {errors && (
          <p className="errorMessage">Email or password is not valid</p>
        )}
      </div>
    </div>
  </div>
  )
}

export default AdminAuthLogin