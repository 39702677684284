import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Adminlogout } from "../feature/AdminFeature";
import { Link, useNavigate } from "react-router-dom";
import Dental from "../assests/Home/Rectangle11.png";
import Registration from "../assests/Home/Rectangle12.png";
import Abstract from "../assests/Home/Rectangle8.png";
import { getRegistreations } from '../feature/AdminFeature';
import { getAbstract } from "../feature/AbstractFeature";
import { getAwardsDetails } from "../feature/AwardFeature";

const AdminDetail = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch=useDispatch();
  const [registerData, setRegisterData] = useState([]);
    const [AbstractData, setAbstractData] = useState([]);
  const [awardData, setAwardData] = useState([]);
  const navigate = useNavigate();

   useEffect(() => {
      const fetchAward = async () => {
        try {
          const res = await dispatch(getAwardsDetails());
          setAwardData(res.payload);
        } catch (err) {
          console.error("Error fetching registrations:", err);
        }
      };
  
      fetchAward();
    }, []);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const res = await dispatch(getRegistreations());
        setRegisterData(res.payload);
      } catch (err) {
        console.error('Error fetching registrations:', err);
      }
    };

    fetchRegistrations();
  }, []);
    useEffect(() => {
      const fetchRegistrations = async () => {
        try {
          const res = await dispatch(getAbstract());
          setAbstractData(res.payload);
        } catch (err) {
          console.error("Error fetching registrations:", err);
        }
      };
  
      fetchRegistrations();
    }, [dispatch]);

  const NumberOfRegister = registerData?.registrations?.length || 0;
  const NumberOfAbstract = AbstractData?.abstractSubmitions?.length || 0;
  const NumberOfAward = awardData?.awards?.length || 0;
  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await dispatch(Adminlogout());
      navigate("/");
    } catch (err) {
      console.error("Error during logout:", err);
    }
  };

  return (
    <div>
      <div className="container-fluid col-11">
        <div className="row mt-5">
        <div className="d-flex justify-content-between">
        <h3>Admin Page</h3>
        <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
      </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <Link to="/admin/registrationDetails" className="linkALL">
              <div className="Registration_img">
                <img
                  src={Registration}
                  className="img-fluid rectangle_images" alt="logo"
                />
                <h1 className="registration_head text-center">Registration Details : {NumberOfRegister}</h1>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <Link to="/admin/abstractDetails" className="linkALL">
              <div className="Registration_img">
                <img src={Abstract} className="img-fluid rectangle_images"  alt="logo"/>
                <h1 className="registration_head text-center">
                  Abstract Submission Details : {NumberOfAbstract}
                </h1>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <Link to="/admin/awardDetails" className="linkALL">
              <div className="Registration_img">
                <img src={Dental} className="img-fluid rectangle_images"  alt="logo"/>
                <h1 className="registration_head text-center">
                  Dental Awards Details: {NumberOfAward} 
                </h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDetail;
