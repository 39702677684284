import Logo from "../assests/WDClogo.png";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Hamburger from "../assests/hamburger.png";
import Close from "../assests/closecircle.png";
import { useState } from "react";

function BasicExample() {
  const navigate = useNavigate();

  const [container, setContainer] = useState(false);

  const openClick = () => {
    setContainer(true);
  };

  const closeClick = () => {
    setContainer(false);
  };

  return (
    <>
      <div className="mobile_container">
        <div
          className={` ${
            container ? "header_mobile menu-open" : "header_mobile"
          }`}
        >
          <div className="container-fluid col-10">
            <div className="d-flex justify-content-between align-items-center mobile_pallete">
              <div>
                <a href="./index.html">
                  <img src={Logo} className="img-fluid  logo ml-5" />
                </a>
              </div>
              <div className="icon-container mr-5">
                <img
                  src={Hamburger}
                  className="img-fluid hamburger"
                  onClick={openClick}
                />
              </div>
            </div>
          </div>
          <div className="mobile-menu">
            <ul className="menu">
              <li className="menu-item">
                <NavLink to="/" className="mx-3">
                  Home
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/commitee" className="mx-3 ">
                  Commitee
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/registerDetail" className="mx-3 ">
                  Registration
                </NavLink>
              </li>

              <li class="dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                   Abstract Submission
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                {/* <NavLink to="/abstract" className=" navbar_logo_expensive dropdown-item">
                    Submission
                  </NavLink> */}
                <NavLink to="/abstractinstruction" className="navbar_logo_expensive dropdown-item">
                    Instruction
                  </NavLink>
               
                </ul>
              </li>

              <li className="menu-item">
                <NavLink to="/award" className="mx-3 ">
                  Awards
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/speaker" className="mx-3 ">
                  Speakers
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/admin" className="mx-3 ">
                  Login
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/contact" className="mx-3 ">
                  Contact Us
                </NavLink>
              </li>
            

              <li className="menu-item">
                <div className="icon-container1 mt-4">
                  <img
                    src={Close}
                    className="img-fluid closed "
                    onClick={closeClick}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <nav className="Navbar navbar navbar-expand-lg navbar-light bg-light sticky-top">
        <div className="container-fluid col-11">
          <NavLink to="/">
            <img src={Logo} className="img-fluid navbar_logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 ">
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/" className="mx-3 Navbar_text">
                  Home
                </NavLink>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/commitee" className="mx-3 Navbar_text">
                  Commitee
                </NavLink>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/registerDetail" className="mx-3 Navbar_text">
                  Registration
                </NavLink>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Abstract Submission
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                {/* <NavLink to="/abstract" className=" navbar_logo_expensive dropdown-item">
                    Submission
                  </NavLink> */}
                <NavLink to="/abstractinstruction" className="navbar_logo_expensive dropdown-item">
                    Instruction
                  </NavLink>
               
                </ul>
              </li>

              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/award" className="mx-3 Navbar_text">
                  Awards
                </NavLink>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/speakers" className="mx-3 Navbar_text">
                  Speakers
                </NavLink>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/admin" className="mx-3 Navbar_text">
                  Login
                </NavLink>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/contact" className="mx-3 Navbar_text">
                  Contact Us
                </NavLink>
              </li>
            </ul>
            <form className="d-flex"></form>
          </div>
        </div>
      </nav>
    </>
  );
}

export default BasicExample;
