import {combineReducers,configureStore} from '@reduxjs/toolkit';
import AuthSliceReducer from '../slice/AuthSlice';
import AdminSliceReducer from '../slice/AdminSlice'

const reducer=combineReducers({
    auth:AuthSliceReducer,
    admin:AdminSliceReducer
});

const store=configureStore({reducer});
export default store;