import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../utils/baseUrl';


export const RegisterUser=createAsyncThunk('auth/register',async(data,{rejectWithValue})=>{
    console.log(data);
    
    try{
     const response=await axios.post(`${BASE_URL}/registration/register`,data,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
     });
    // const response=await axios.post("http://localhost:5000/api/v1/registration/register",data);
     console.log(response.data)
     return response.data
    }
    catch(err){
     return rejectWithValue(err);
    }
});
