import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const RegisterDetail = () => {

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-12">
          <div className="validThings">
            <div>
              <h2 className="text-center details_head"> Registration</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 d-flex justify-content-center">
          <div className="col-lg-10 col-12">
            <h2 className=" head_general">Dear delegates </h2>
            <li className="head_para my-lg-3 my-3 d-flex">
              All payments may be made through NEFT transfer.
              Once transferred, please send the payment confirmation screenshot
              via WhatsApp to 9884042526.
            </li>
            <li className="head_para my-lg-3 my-3 d-flex flex-column">
              The AC details are given below.
              <p className="my-lg-4 my-3" style={{ fontWeight: "800" }}>
                WORLD DENTISTS ASSOCIATION
              </p>
              <ul className="bankDetailList">
                <li className="bankDetailitems">
                  <p>BANK : INDIAN OVERSEAS BANK</p>
                </li>
                <li className="bankDetailitems">
                  <p>BRANCH :MOGAPPAIR EAST</p>
                </li>
                <li className="bankDetailitems">
                  <p>ACCOUNT NUMBER :169502000001999</p>
                </li>
                <li className="bankDetailitems">
                  <p>IFSC CODE : IOBA0001695</p>
                </li>
              </ul>
            </li>
          </div>
        </div>

      <div className="container-fluid col-10 mt-5">
        <div className="row ">
          <div className="col-lg-12 mt-4">
            <h2 className="conference_register">
              CONFERENCE REGISTRATION - Early Bird Registration - till 31st March
            </h2>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="package_main">
              <div className="d-flex justify-content-around align-items-center flex-column px-1 py-1">
                <h3 className="pacakgeDetail_head">
                  UNDERGRADUATE STUDENT PACKAGE:
                </h3>
                <ul className="py-2">
                  <li className="packageDetail_para">
                    Includes – Registration
                  </li>
                  <li className="packageDetail_para">
                    Registration Gift Packs
                  </li>
                  <li className="packageDetail_para">
                    Attend Scientific Sessions
                  </li>
                  <li className="packageDetail_para">Present Paper & Poster</li>
                  <li className="packageDetail_para">
                    High Tea Snacks for 2 Days
                  </li>
                  <li className="packageDetail_para">Lunch for 2 Days</li>
                  <li className="packageDetail_para">
                    Attend World Dental Awards
                  </li>
                  <li className="packageDetail_para">
                    Presidential Banquet Dinner.
                  </li>
                </ul>
                <div className="container-fluid">
                  <div className="fixed_box">
                    <p className="price_chart">INR ₹10,000 + 18% GST</p>

                    {/* {Date.now() < new Date("2025-04-01") && (
                      <Link to="/registration?role=ug" className="linkALL">
                        <button
                          type="button"
                          className="btn_details center-block d-block mx-auto my-2"
                        >
                          Register
                        </button>
                      </Link>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main">
              <div className="d-flex justify-content-around align-items-center flex-column px-1 py-1">
                <h3 className="pacakgeDetail_head">
                  POSTGRADUATE STUDENT & DENTIST PACKAGE:
                </h3>
                <ul className="">
                  <li className="packageDetail_para">
                    Includes – Registration
                  </li>
                  <li className="packageDetail_para">
                    Registration Gift Packs
                  </li>
                  <li className="packageDetail_para">
                    Attend Scientific Sessions
                  </li>
                  <li className="packageDetail_para">Present Paper & Poster</li>
                  <li className="packageDetail_para">
                    High Tea Snacks for 2 Days
                  </li>
                  <li className="packageDetail_para">
                    Lunch for 2 Days & Attend World Dental Awards
                  </li>
                  <li className="packageDetail_para">
                    Presidential Banquet Dinner
                  </li>
                </ul>
                <div className="container-fluid">
                  <div className="fixed_box">
                    <p className="price_chart">INR ₹19,000 + 18% GST</p>
                    {/* {Date.now() < new Date("2025-04-01") && (
                      <Link to="/registration?role=pg" className="linkALL">
                        <button
                          type="button"
                          className="btn_details center-block d-block mx-auto my-2"
                        >
                          Register
                        </button>
                      </Link>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main">
              <div className="d-flex justify-content-around align-items-center flex-column px-1 py-1">
                <h3 className="pacakgeDetail_head">
                  FOR ACCOMPANYING PERSON (NON DENTIST):
                </h3>
                <ul className="">
                  <li className="packageDetail_para">
                    Includes – Registration
                  </li>
                  <li className="packageDetail_para">
                    Attend Scientific Sessions
                  </li>
                  <li className="packageDetail_para">
                    High tea Snacks for 2 days
                  </li>
                  <li className="packageDetail_para">Lunch for 2 days</li>
                  <li className="packageDetail_para">
                    Attend World Dental Awards
                  </li>
                  <li className="packageDetail_para">
                    Presidential Banquet Dinner
                  </li>
                </ul>
                <div className="container-fluid">
                  <div className="fixed_box">
                    <p className="price_chart">INR ₹15,000 + 18% GST</p>
                    {/* <Link to="/registration?role=ap" className="linkALL"  >
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid col-10 mt-5">
        <div className="row ">
          <div className="col-lg-12 mt-4">
            <h2 className="conference_register">
              CONFERENCE REGISTRATION From April 1<sup>st</sup> Till  Sep 15
              <sup>th</sup>
            </h2>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="package_main">
              <div className="d-flex justify-content-around align-items-center flex-column px-1 py-1">
                <h3 className="pacakgeDetail_head">
                  UNDERGRADUATE STUDENT PACKAGE:
                </h3>
                <ul className="py-2">
                  <li className="packageDetail_para">
                    Includes – Registration
                  </li>
                  <li className="packageDetail_para">
                    Registration Gift Packs
                  </li>
                  <li className="packageDetail_para">
                    Attend Scientific Sessions
                  </li>
                  <li className="packageDetail_para">Present Paper & Poster</li>
                  <li className="packageDetail_para">
                    High Tea Snacks for 2 Days
                  </li>
                  <li className="packageDetail_para">Lunch for 2 Days</li>
                  <li className="packageDetail_para">
                    Attend World Dental Awards
                  </li>
                  <li className="packageDetail_para">
                    Presidential Banquet Dinner.
                  </li>
                </ul>
                <div className="container-fluid">
                  <div className="fixed_box">
                    <p className="price_chart">INR ₹12,000 + 18% GST</p>
                    {Date.now() > new Date("2025-04-01") &&
                      Date.now() < new Date("2025-08-01") && (
                        <Link to="/registration?role=ug" className="linkALL">
                          <button
                            type="button"
                            className="btn_details center-block d-block mx-auto my-2"
                          >
                            Register
                          </button>
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main">
              <div className="d-flex justify-content-around align-items-center flex-column px-1 py-1">
                <h3 className="pacakgeDetail_head">
                  POSTGRADUATE STUDENT & DENTIST PACKAGE:
                </h3>
                <ul className="">
                  <li className="packageDetail_para">
                    Includes – Registration
                  </li>
                  <li className="packageDetail_para">
                    Registration Gift Packs
                  </li>
                  <li className="packageDetail_para">
                    Attend Scientific Sessions
                  </li>
                  <li className="packageDetail_para">Present Paper & Poster</li>
                  <li className="packageDetail_para">
                    High Tea Snacks for 2 Days
                  </li>
                  <li className="packageDetail_para">
                    Lunch for 2 Days & Attend World Dental Awards
                  </li>
                  <li className="packageDetail_para">
                    Presidential Banquet Dinner
                  </li>
                </ul>
                <div className="container-fluid">
                  <div className="fixed_box">
                    <p className="price_chart">INR ₹20,000 + 18% GST</p>
                    {Date.now() > new Date("2025-04-01") &&
                      Date.now() < new Date("2025-08-01") && (
                        <Link to="/registration?role=pg" className="linkALL">
                          <button
                            type="button"
                            className="btn_details center-block d-block mx-auto my-2"
                          >
                            Register
                          </button>
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main">
              <div className="d-flex justify-content-around align-items-center flex-column px-1 py-1">
                <h3 className="pacakgeDetail_head">
                  FOR ACCOMPANYING PERSON (NON DENTIST):
                </h3>
                <ul className="">
                  <li className="packageDetail_para">
                    Includes – Registration
                  </li>
                  <li className="packageDetail_para">
                    Attend Scientific Sessions
                  </li>
                  <li className="packageDetail_para">
                    High tea Snacks for 2 days
                  </li>
                  <li className="packageDetail_para">Lunch for 2 days</li>
                  <li className="packageDetail_para">
                    Attend World Dental Awards
                  </li>
                  <li className="packageDetail_para">
                    Presidential Banquet Dinner
                  </li>
                </ul>
                <div className="container-fluid">
                  <div className="fixed_box">
                    <p className="price_chart">INR ₹15,000 + 18% GST</p>
                    {/* <Link to="/registration?role=ap" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid col-10 mt-5">
        <div className="row ">
          <div className="col-lg-12 mt-4">
            <h2 className="conference_register">
              CONFERENCE REGISTRATION Sep 16<sup>th</sup> Till Sep 30
              <sup>th</sup>
            </h2>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="package_main">
              <div className="d-flex justify-content-around align-items-center flex-column px-1 py-1">
                <h3 className="pacakgeDetail_head">
                  UNDERGRADUATE STUDENT PACKAGE:
                </h3>
                <ul className="py-2">
                  <li className="packageDetail_para">
                    Includes – Registration
                  </li>
                  <li className="packageDetail_para">
                    Registration Gift Packs
                  </li>
                  <li className="packageDetail_para">
                    Attend Scientific Sessions
                  </li>
                  <li className="packageDetail_para">Present Paper & Poster</li>
                  <li className="packageDetail_para">
                    High Tea Snacks for 2 Days
                  </li>
                  <li className="packageDetail_para">Lunch for 2 Days</li>
                  <li className="packageDetail_para">
                    Attend World Dental Awards
                  </li>
                  <li className="packageDetail_para">
                    Presidential Banquet Dinner.
                  </li>
                </ul>
                <div className="container-fluid">
                  <div className="fixed_box">
                    <p className="price_chart">INR ₹14,000 + 18% GST</p>
                    {Date.now() > new Date("2025-09-01") &&
                      Date.now() < new Date("2025-09-31") && (
                        <Link to="/registration?role=ug" className="linkALL">
                          <button
                            type="button"
                            className="btn_details center-block d-block mx-auto my-2"
                          >
                            Register
                          </button>
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main">
              <div className="d-flex justify-content-around align-items-center flex-column px-1 py-1">
                <h3 className="pacakgeDetail_head">
                  POSTGRADUATE STUDENT & DENTIST PACKAGE:
                </h3>
                <ul className="">
                  <li className="packageDetail_para">
                    Includes – Registration
                  </li>
                  <li className="packageDetail_para">
                    Registration Gift Packs
                  </li>
                  <li className="packageDetail_para">
                    Attend Scientific Sessions
                  </li>
                  <li className="packageDetail_para">Present Paper & Poster</li>
                  <li className="packageDetail_para">
                    High Tea Snacks for 2 Days
                  </li>
                  <li className="packageDetail_para">
                    Lunch for 2 Days & Attend World Dental Awards
                  </li>
                  <li className="packageDetail_para">
                    Presidential Banquet Dinner
                  </li>
                </ul>
                <div className="container-fluid">
                  <div className="fixed_box">
                    <p className="price_chart">INR ₹22,000 + 18% GST</p>
                    {Date.now() > new Date("2025-09-01") &&
                      Date.now() < new Date("2025-09-31") && (
                        <Link to="/registration?role=pg" className="linkALL">
                          <button
                            type="button"
                            className="btn_details center-block d-block mx-auto my-2"
                          >
                            Register
                          </button>
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main">
              <div className="d-flex justify-content-around align-items-center flex-column px-1 py-1">
                <h3 className="pacakgeDetail_head">
                  FOR ACCOMPANYING PERSON (NON DENTIST):
                </h3>
                <ul className="">
                  <li className="packageDetail_para">
                    Includes – Registration
                  </li>
                  <li className="packageDetail_para">
                    Attend Scientific Sessions
                  </li>
                  <li className="packageDetail_para">
                    High tea Snacks for 2 days
                  </li>
                  <li className="packageDetail_para">Lunch for 2 days</li>
                  <li className="packageDetail_para">
                    Attend World Dental Awards
                  </li>
                  <li className="packageDetail_para">
                    Presidential Banquet Dinner
                  </li>
                </ul>
                <div className="container-fluid">
                  <div className="fixed_box">
                    <p className="price_chart">INR ₹17,000 + 18% GST</p>
                    {/* <Link to="/registration?role=ap" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterDetail;
