import "./App.css";
import AdminAuthLogin from "./pages/AdminAuthLogin";
import Home from "./pages/Home";
import Register from "./pages/Register";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminVerify from "./routes/AdminVerify";
import { useDispatch } from "react-redux";
import { setCredentials } from "./slice/AdminSlice";
import axios from "axios";
import { useEffect } from "react";
import { BASE_URL } from "./utils/baseUrl";
import AdminDetail from "./pages/AdminDetail";
import AdminRegistrationDetails from "./pages/AdminRegistrationDetails";
import AwardRegistration from "./pages/AwardRegistration";
import AbstractRegistration from "./pages/AbstractRegistration";
import AdminAbstractDetails from "./pages/AdminAbstractDetails";
import AdmiAwardDetails from "./pages/AdmiAwardDetails";
import Committe from "./pages/Commitee";
import RegisterDetail from "./pages/RegistrationDetail";
import Navbar from "./component/Navbar";
import Footer from "./component/Footer";
import Contact from "./pages/Contact";
import Speaker from "./pages/Speaker";
import AwardDetail from "./pages/AwardDetails";
import Abstractinstruction from "./pages/Abstractinstruction";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleFetch = async (e) => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/refresh`, {
          withCredentials: true,
        });
        dispatch(setCredentials(response.data));

        console.log("app js", response.data);
      } catch (err) {
        console.log(err);
      }
    };
    handleFetch();
  }, [dispatch]);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/commitee" element={<Committe />} />
        <Route path="/registerDetail" element={<RegisterDetail />} />
        <Route path="/registration" element={<Register />} />
        <Route path="/admin" element={<AdminAuthLogin />} />
        <Route path="/awardRegistration" element={<AwardRegistration />} />
        <Route path="/award" element={<AwardDetail />} />
        <Route path="/abstract" element={<AbstractRegistration />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/speakers" element={<Speaker />} />
        <Route path="/abstractinstruction" element={<Abstractinstruction />} />
        <Route element={<AdminVerify />}>
          <Route path="/adminDetails" element={<AdminDetail />} />
          <Route
            path="/admin/registrationDetails"
            element={<AdminRegistrationDetails />}
          />
          <Route
            path="/admin/abstractDetails"
            element={<AdminAbstractDetails />}
          />
          <Route path="/admin/awardDetails" element={<AdmiAwardDetails />} />
        </Route>
        <Route path="/*" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
