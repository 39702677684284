import React, { useEffect } from "react";
import { Link } from "react-router-dom";


const AwardDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="center">

      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="award_detailsPage">
            <h2 className="award_head">Excellence in Dentistry Awards 2025</h2>
            <Link to="/award" className="linkALL">
              <button
                type="button"
                className="btn_details1 center-block d-block mx-auto mt-4"
              >
                Apply For Award
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="container-fluid col-lg-10 col-12 mt-5">
        <div className="row">
          <div className="col-lg-12 col-12">
            <h2 className="award_head_para">
         The Excellence in Dentistry Awards 2025 is a prestigious honour that
acknowledges and celebrates the exceptional contributions and achievements of
dental professionals who have made a significant impact in the field of dentistry.
The awards will be presented in the following categories at the Award ceremony on
th the night of 30 September 2025.
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12 col-12 mt-4">
            <ul>
              <li className="award_para mt-2">
                BEST DENTAL ADMINISTRATOR-2025
              </li>
              <li className="award_para mt-2">
                OUTSTANDING DENTIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
                OUTSTANDING YOUNG DENTIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
                YOUNG UNDER GRADUATE RESEARCHER AWARD-2025
              </li>
              <li className="award_para mt-2">
                POST GRADUATE RESEARCHER AWARD-2025
              </li>
              <li className="award_para mt-2">
                BEST STARTUP CLINIC AWARD-2025
              </li>
              <li className="award_para mt-2">
                BEST MULTI SPECIALITY PRACTICE-2025
              </li>
              <li className="award_para mt-2">PEDODONTIST OF THE YEAR-2025</li>
              <li className="award_para mt-2">
                YOUNG PEDODONTIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
                BEST RESEARCHER IN PEDODONTICS-2025
              </li>
              <li className="award_para mt-2">ORTHODONTIST OF THE YEAR-2025</li>
              <li className="award_para mt-2">
                YOUNG ORTHODONTIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
                BEST RESEARCHER IN ORTHODONTICS-2025
              </li>
              <li className="award_para mt-2">
                BEST RESEARCHER IN ORAL MEDICINE & RADIOLOGY-2025
              </li>




              <li className="award_para mt-2">
              BEST SPECIALIST FOR ORAL MEDICINE & RADIOLOGY-2025
              </li>
              <li className="award_para mt-2">
        YOUNG SPECIALIST IN ORAL MEDICINE & RADIOLOGY-2025
              </li>
              <li className="award_para mt-2">
             ENDODONTIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
                YOUNG ENDODONTIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
         BEST RESEARCHER IN ENDODONTICS-2025
              </li>
              <li className="award_para mt-2">
        PERIODONTIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
YOUNG PERIODONTIST OF THE YEAR-2025
              </li>
            </ul>
          </div>

          <div className="col-lg-6 col-md-12 col-12 mt-4">
            <ul>
              <li className="award_para mt-2">
                BEST RESEARCHER IN PERIODONTICS-2025
              </li>
              <li className="award_para mt-2">ORAL SURGEON OF THE YEAR-2025</li>
              <li className="award_para mt-2">
                YOUNG ORAL SURGEON OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
                BEST RESEARCHER IN ORAL SURGERY-2025
              </li>
              <li className="award_para mt-2">
                PROSTHODONTIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
                YOUNG PROSTHODONTIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
                BEST RESEARCHER IN PROSTHODONTICS-2025
              </li>
              <li className="award_para mt-2">
                ORAL PATHOLOGIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
   YOUNG ORAL PATHOLOGIST OF THE YEAR-2025
              </li>
              <li className="award_para mt-2">
              BEST RESEARCHER IN ORAL PATHOLOGY-2025
              </li>
  
              <li className="award_para mt-2">
                BEST EDUCATOR - PEDODONTICS-2025
              </li>
              <li className="award_para mt-2">
                YOUNG SPECIALIST IN ORAL MEDICINE & RADIOLOGY-2025
              </li>
              <li className="award_para mt-2">
               BEST EDUCATOR - PERIODONTICS-2025
              </li>
              <li className="award_para mt-2">
          BEST EDUCATOR - PUBLIC HEALTH DENTISTRY-2025
              </li>
              <li className="award_para mt-2">BEST EDUCATOR - PROSTHODONTICS 2025</li>
              <li className="award_para mt-2">
      BEST EDUCATOR - ORTHODONTICS-2025
              </li>
              <li className="award_para mt-2">BEST EDUCATOR - PEDODONTICS-2025
</li>
          <li className="award_para mt-2">BEST EDUCATOR - ORAL PATHOLOGY-2025
</li>
          <li className="award_para mt-2">BEST EDUCATOR - ORAL SURGERY-2025

</li>
          <li className="award_para mt-2">BEST EDUCATOR - IMPLANTOLOGY-2025

</li>
          <li className="award_para mt-2">BEST EDUCATOR - ORAL MEDICINE-2025

</li>
          <li className="award_para mt-2">BEST DENTAL COLLEGE-2025

</li>

            </ul>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-12 col-12">
            <div className="award_backup">
              <h2 className="award_title">
                ONLY A REGISTERED PARTICIPANT CAN APPLY FOR AN AWARD
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardDetail;
