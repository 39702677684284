import { createSlice } from "@reduxjs/toolkit";
import { AdminLogin } from "../feature/AdminFeature";

const AdminSlice=createSlice({
    name:"admin",
    initialState:{
        status:"idle",
        isAuthenticated:false,
        user:[],
        error:null
    },
    reducers:{
        setCredentials:(state,action)=>{
            console.log("action payload",action.payload)
                 state.isAuthenticated=true;
            state.user=[action.payload];
            state.status="Success";
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(AdminLogin.pending,(state,action)=>{
            state.status="Loading"
        })
        .addCase(AdminLogin.fulfilled,(state,action)=>{
            state.isAuthenticated=true;
            console.log("addcase login",action.payload)
            state.user=[action.payload];
            state.status="Success";
        })
        .addCase(AdminLogin.rejected,(state,action)=>{
            state.isAuthenticated=false;
            state.status="Failed";
            state.error=action.error
        })
    }
});

export const {setCredentials}=AdminSlice.actions;

export default AdminSlice.reducer;