import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { getAwardsDetails } from "../feature/AwardFeature";

const AdmiAwardDetails = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const [registerData, setRegisterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(3);

  useEffect(() => {
    const updatePostsPerPage = () => {
      const width = window.innerWidth;
      if (width >= 992) {
        setPostsPerPage(3);
      } else if (width >= 768) {
        setPostsPerPage(2);
      } else {
        setPostsPerPage(1);
      }
    };

    updatePostsPerPage();

    window.addEventListener("resize", updatePostsPerPage);

    return () => {
      window.removeEventListener("resize", updatePostsPerPage);
    };
  }, []);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const res = await dispatch(getAwardsDetails());
        setRegisterData(res.payload);
      } catch (err) {
        console.error("Error fetching registrations:", err);
      }
    };

    fetchRegistrations();
  }, []);

  console.log(registerData);
  const offset = currentPage * postsPerPage;
  const currentData = registerData?.awards?.slice(
    offset,
    offset + postsPerPage
  );

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  console.log(currentData);
  return (
    <div className="container-fluid" style={{ backgroundColor: "#f1f1f1" }}>
      <div className="d-flex justify-content-around pt-5 align-items-center">
        <Link
          to={"/adminDetails"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <IoArrowBackCircleOutline className="fs-1 text-black" />
        </Link>
        <h3 className="text-center fw-bold">
          Award Details - {registerData?.awards?.length}
        </h3>
        <span></span>
      </div>
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-lg-12 my-3 col-md-12 col-12 d-flex justify-content-center align-items-center flex-lg-row flex-md-row flex-column">
          {Array.isArray(currentData) && currentData.length > 0 ? (
            currentData.map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-11 my-4">
                <div className="col-lg-11 col-md-11 col-10 card p-3 mx-auto">
                  <img
                    src={item.image}
                    className="card-img-top cardImg"
                    alt="img"
                  />
                  <div className="card-body">
                    <p className="">
                      <span className="fw-bold">Name : </span>
                      {item.name || "No Name Available"}
                    </p>
                    <p className="">
                      <span className="fw-bold">Email : </span>
                      {item.email || "No Email Available"}
                    </p>
                    <p className="">
                      <span className="fw-bold">Gender : </span>
                      {item.gender || "No Gender Available"}
                    </p>
                    <p className="">
                      <span className="fw-bold">Date Of Birth : </span>
                      {item.dob || "No DOB Available"}
                    </p>
                    <p className="">
                      <span className="fw-bold">Phone Number :</span>
                      {item.mobileNumber || "No Mobile Number Available"}
                    </p>
                    <p className="">
                      <span className="fw-bold">Price : </span>
                      {item.price || "No Price Available"}
                    </p>
                    <p className="">
                      <span className="fw-bold">Organization : </span>
                      {item.organization || "No Organization Available"}
                    </p>
                    <p className="">
                      <span className="fw-bold">Role : </span>
                      {item.role?.toUpperCase() || "No Role Available"}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No Data Available</p>
          )}
        </div>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(registerData.awards?.length / postsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default AdmiAwardDetails;
