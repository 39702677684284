import { createSlice } from "@reduxjs/toolkit";
import {RegisterUser} from '../feature/AuthFeature';

const AuthSlice=createSlice({
    name:"auth",
    initialState:{
        status:"idle",
        isAuthenticated:false,
        user:[],
        error:null
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(RegisterUser.pending,(state)=>{
            state.status="loading";
        }).addCase(RegisterUser.fulfilled,(state,action)=>{ 
            state.status="success";
            state.isAuthenticated=true;
            state.user=[action.payload];  
        })
        .addCase(RegisterUser.rejected,(state,action)=>{
            state.status="failed";
            state.error=action.error.message;
        })
    }
});

export default AuthSlice.reducer