import React, { useEffect } from "react";
import worldImg from "../assests/contact/textImg.png";
import logo from "../assests/contact/logo.png";
import logos from "../assests/contact/logo1.png";
const Speaker = () => {

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div>
      <div className="contact_background pt-5 pb-5">
        <div className="container-fluid col-lg-12 col-12">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-10 col-md-12 col-12">
              <h2 className="contact_head"> WEBSITE UNDER CONSTRUCTION</h2>
              </div>
             <div className="col-lg-6 col-md-12 col-12">
             <img
                src={worldImg}
                className="img-fluid textImg mt-5 center-block d-block mx-auto mt-lg-4"
              />
              <h2 className="contact_head">Singapore 2025</h2>
              <div className="d-flex align-items-center justify-content-center mt-lg-4 mt-5">
                <div>
                  <img src={logo} className="img-fluid contact_logo mx-2" />
                </div>
                <div>
                  <img src={logos} className="img-fluid contact_logo mx-2" />
                </div>
              </div>
             </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Speaker;
