import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/baseUrl";

export const AwardRegistrationed = createAsyncThunk(
  "award/registration",
  async (data, { rejectWithValue }) => {
    try {
      console.log("award data", data);
      const res = await axios.post(`${BASE_URL}/award/submit`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return res.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const getAwardsDetails = createAsyncThunk(
  "admin/award",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/admin/award`, {
        withCredentials: true,
      });
      return res.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);
