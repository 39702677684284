import React, { useEffect } from "react";
import oneman from "../assests/commitee/one.png";
import two from "../assests/commitee/two.png";
import three from "../assests/commitee/three.png";
import four from "../assests/commitee/four.png";
import five from "../assests/commitee/five.png";
import six from "../assests/commitee/six.png";
import seven from "../assests/commitee/seven.png";
import eight from "../assests/commitee/eight.png";
import nine from "../assests/commitee/nine.png";
import ten from "../assests/commitee/ten.png";
import eleven from "../assests/commitee/eleven.png";
import twelve from "../assests/commitee/twelve.png";

import ones from "../assests/commitee/ones.png";
import twos from "../assests/commitee/twos.png";
import threes from "../assests/commitee/threes.png";
import fours from "../assests/commitee/fours.png";

const Committe = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="center">


      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="commitee_banner">
            <h2 className="commitee_head">Committee</h2>
          </div>
        </div>
      </div>

      <div className="container-fluid col-lg-10 col-12">
        
        <div className="row">
          <div className="col-lg-12 col-12 mt-5">
            <h2 className="commitee_head_organize">
              ORGANIZING COMMITTEE MEMBERS
            </h2>
            <p className="commitee_para mt-4">
              When organizing a world dental conference, the committee members
              play a crucial role in ensuring the event's success. The
              organizing committee is responsible for managing different aspects
              of the conference, such as scheduling, logistics, marketing, and
              finance.
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={oneman}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img src={two} className="img-fluid center-block d-block mx-auto"
            alt="logo" />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={three}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={four}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={five}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img src={six} className="img-fluid center-block d-block mx-auto" 
            alt="logo"/>
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={ones}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={threes}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={seven}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={twos}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={fours}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={eight}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={eleven}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img src={ten} className="img-fluid center-block d-block mx-auto"
            alt="logo" />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={nine}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={twelve}
              className="img-fluid center-block d-block mx-auto"
              alt="logo"
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default Committe;
