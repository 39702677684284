import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { AwardRegistrationed } from '../feature/AwardFeature';
import { unwrapResult } from '@reduxjs/toolkit';
import Footer from '../component/Footer';
import WDCLogo from "../assests/register/WDClogo.png";
import WDALogo from "../assests/register/WDAlogo.png";
import Select from "react-select";

const USER_REGEX = /^[A-z][A-z0-9-_ ]{3,23}$/;
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const MOBILE_REGEX = /^[0-9]{10}$/;
const DOB_REGEX = /^\d{4}-\d{2}-\d{2}$/;
const GENDER_REGEX = /^(?:male|female|other)$/;
const ORGANIZATION_REGEX = /^[A-z][A-z0-9-_ ]{3,68}$/;

const AwardRegistration = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const userRef=useRef();

     const [name, setName] = useState("");
      const [validName, setValidName] = useState(false);
      const [userFocus, setUserFocus] = useState(false);
    
    
      const [email, setEmail] = useState("");
      const [validEmail, setValidEmail] = useState(false);
      const [emailFocus, setEmailFocus] = useState(false);
    
    
      const [mobileNumber, setMobileNumber] = useState("");
      const [validMobileNumber, setValidMobileNumber] = useState(false);
      const [mobileFocus, setMobileFocus] = useState(false);
    
    
    
      const [dob, setDob] = useState("");
      const [validDob, setValidDob] = useState(false);
      const [dobFocus, setDobFocus] = useState(false);
    
      console.log(dob)
    
    
    
      const [gender, setGender] = useState("");
      const [validgender, setValidgender] = useState(false);
      const [genderFocus, setGenderFocus] = useState(false);
    
    
      const [organization, setOrganization] = useState("");
      const [validOrganization, setValidOrganization] = useState(false);
      const [organizationFocus, setOrganizationFocus] = useState(false);
    
      const [image, setImage] = useState("");
      const [validImage, setValidImage] = useState(false);
      const [imageFocus, setImageFocus] = useState(false);

        const [attachment,setAttachment]=useState("");
        const [validAttachment,setValidAttachment]=useState(false);
        const [attachmentFocus,setAttachmentFocus]=useState(false);

      const options = [
    {
      label: "BEST DENTAL ADMINISTRATOR-2025",
      value: "BEST DENTAL ADMINISTRATOR-2025",
    },
    {
      label: "OUTSTANDING DENTIST OF THE YEAR-2025",
      value: "OUTSTANDING DENTIST OF THE YEAR-2025",
    },
    {
      label: "OUTSTANDING YOUNG DENTIST OF THE YEAR-2025",
      value: "OUTSTANDING YOUNG DENTIST OF THE YEAR-2025",
    },
    {
      label: "YOUNG UNDER GRADUATE RESEARCHER AWARD-2025",
      value: "YOUNG UNDER GRADUATE RESEARCHER AWARD-2025",
    },
    {
      label: "POST GRADUATE RESEARCHER AWARD-2025",
      value: "POST GRADUATE RESEARCHER AWARD-2025",
    },
    {
      label: "BEST STARTUP CLINIC AWARD-2025",
      value: "BEST STARTUP CLINIC AWARD-2025",
    },
    {
      label: "BEST MULTI SPECIALITY PRACTICE-2025",
      value: "BEST MULTI SPECIALITY PRACTICE-2025",
    },
    {
      label: "PEDODONTIST OF THE YEAR-2025",
      value: "PEDODONTIST OF THE YEAR-2025",
    },
    {
      label: "YOUNG PEDODONTIST OF THE YEAR-2025",
      value: "YOUNG PEDODONTIST OF THE YEAR-2025",
    },
    {
      label: "BEST RESEARCHER IN PEDODONTICS-2025",
      value: "BEST RESEARCHER IN PEDODONTICS-2025",
    },
    {
      label: "ORTHODONTIST OF THE YEAR-2025",
      value: "ORTHODONTIST OF THE YEAR-2025",
    },
    {
      label: "YOUNG ORTHODONTIST OF THE YEAR-2025",
      value: "YOUNG ORTHODONTIST OF THE YEAR-2025",
    },
    {
      label: "BEST RESEARCHER IN ORTHODONTICS-2025",
      value: "BEST RESEARCHER IN ORTHODONTICS-2025",
    },
    {
      label: "BEST RESEARCHER IN ORAL MEDICINE & RADIOLOGY-2025",
      value: "BEST RESEARCHER IN ORAL MEDICINE & RADIOLOGY-2025",
    },
    {
      label: "BEST SPECIALIST FOR ORAL MEDICINE & RADIOLOGY-2025",
      value: "BEST SPECIALIST FOR ORAL MEDICINE & RADIOLOGY-2025",
    },
    {
      label: "YOUNG SPECIALIST IN ORAL MEDICINE & RADIOLOGY-2025",
      value: "YOUNG SPECIALIST IN ORAL MEDICINE & RADIOLOGY-2025",
    },
    {
      label: "ENDODONTIST OF THE YEAR-2025",
      value: "ENDODONTIST OF THE YEAR-2025",
    },
    {
      label:
        "YOUNG ENDODONTIST OF THE YEAR-2025",
      value:
        "YOUNG ENDODONTIST OF THE YEAR-2025",
    },
    {
      label: "YOUNG PERIODONTIST OF THE YEAR-2025",
      value: "YOUNG PERIODONTIST OF THE YEAR-2025",
    },
    {
      label: "BEST RESEARCHER IN ENDODONTICS-2025",
      value: "BEST RESEARCHER IN ENDODONTICS-2025",
    },
    {
      label: "PERIODONTIST OF THE YEAR-2025",
      value: "PERIODONTIST OF THE YEAR-2025",
    },
    {
      label: "YOUNG PERIODONTIST OF THE YEAR-2025",
      value: "YOUNG PERIODONTIST OF THE YEAR-2025",
    },



    {
      label: "BEST RESEARCHER IN PERIODONTICS-2025",
      value: "BEST RESEARCHER IN PERIODONTICS-2025",
    },


    {
      label: "ORAL SURGEON OF THE YEAR-2025",
      value: "ORAL SURGEON OF THE YEAR-2025",
    },
     {
    label: "YOUNG ORAL SURGEON OF THE YEAR-2025",
    value: "YOUNG ORAL SURGEON OF THE YEAR-2025",
  },
  {
    label: "BEST RESEARCHER IN ORAL SURGERY-2025",
    value: "BEST RESEARCHER IN ORAL SURGERY-2025",
  },
  {
    label: "PROSTHODONTIST OF THE YEAR-2025",
    value: "PROSTHODONTIST OF THE YEAR-2025",
  },
  {
    label: "YOUNG PROSTHODONTIST OF THE YEAR-2025",
    value: "YOUNG PROSTHODONTIST OF THE YEAR-2025",
  },
  {
    label: "BEST RESEARCHER IN PROSTHODONTICS-2025",
    value: "BEST RESEARCHER IN PROSTHODONTICS-2025",
  },
  {
    label: "ORAL PATHOLOGIST OF THE YEAR-2025",
    value: "ORAL PATHOLOGIST OF THE YEAR-2025",
  },
  {
    label: "YOUNG ORAL PATHOLOGIST OF THE YEAR-2025",
    value: "YOUNG ORAL PATHOLOGIST OF THE YEAR-2025",
  },
  {
    label: "BEST RESEARCHER IN ORAL PATHOLOGY-2025",
    value: "BEST RESEARCHER IN ORAL PATHOLOGY-2025",
  },
  {
    label: "BEST EDUCATOR - PERIODONTICS-2025",
    value: "BEST EDUCATOR - PERIODONTICS-2025",
  },
  {
    label: "BEST EDUCATOR - PUBLIC HEALTH DENTISTRY-2025",
    value: "BEST EDUCATOR - PUBLIC HEALTH DENTISTRY-2025",
  },
  {
    label: "BEST EDUCATOR - PROSTHODONTICS-2025",
    value: "BEST EDUCATOR - PROSTHODONTICS-2025",
  },
  {
    label: "BEST EDUCATOR - ORTHODONTICS-2025",
    value: "BEST EDUCATOR - ORTHODONTICS-2025",
  },
  {
    label: "BEST EDUCATOR - PEDODONTICS-2025",
    value: "BEST EDUCATOR - PEDODONTICS-2025",
  },
  {
    label: "BEST EDUCATOR - ORAL PATHOLOGY-2025",
    value: "BEST EDUCATOR - ORAL PATHOLOGY-2025",
  },
  {
    label: "BEST EDUCATOR - ORAL SURGERY-2025",
    value: "BEST EDUCATOR - ORAL SURGERY-2025",
  },
  {
    label: "BEST EDUCATOR - IMPLANTOLOGY-2025",
    value: "BEST EDUCATOR - IMPLANTOLOGY-2025",
  },
  {
    label: "BEST EDUCATOR - ORAL MEDICINE-2025",
    value: "BEST EDUCATOR - ORAL MEDICINE-2025",
  },
  {
    label: "BEST DENTAL COLLEGE-2025",
    value: "BEST DENTAL COLLEGE-2025",
  },
  ];

    const [qualification,setQualification]=useState("");
    const [designation,setDesignation]=useState("");
    const [specialization,setSpecialization]=useState("");
    const [award,setAward]=useState("");
    const [validAward,setValidAward]=useState(false);
  
      const [value, setValue] = useState("");
    const [message,setMessage]=useState("");

    const dispatch=useDispatch();
useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setValidName(USER_REGEX.test(name));
  }, [name]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidMobileNumber(MOBILE_REGEX.test(mobileNumber));
  }, [mobileNumber]);


  useEffect(() => {
    setValidDob(DOB_REGEX.test(dob));
  }, [dob])


  useEffect(() => {
    setValidgender(GENDER_REGEX.test(gender));
  }, [gender])

  useEffect(() => {
    setValidOrganization(ORGANIZATION_REGEX.test(organization));
  }, [organization]);


    const handleAvatar=(e)=>{
        const file=Array.from(e.target.files)[0];
        setImage(file);
    }

    const handleAttachment=(e)=>{
        const file=Array.from(e.target.files)[0];
        setAttachment(file);
    };

    useEffect(()=>{
        setAward(value?.value);
    },[value]);

    useEffect(()=>{
        if(award){
            setValidAward(true);
        }  
    },[award]);


 useEffect(() => {
   if(image?.size <= 2*1024*1024){
     setValidImage(true);
   }
   else{
     setValidImage(false);
   }
 },[image]);


 useEffect(() => {
   if(attachment?.size <= 2*1024*1024){
     setValidAttachment(true);
   }
   else{
     setValidAttachment(false);
   }
 },[attachment]);

    const handleSubmit=(e)=>{
        e.preventDefault();

        if(!validImage || !validAttachment || !validAward || !validName || !validEmail || !validMobileNumber || !validDob || !validgender || !validOrganization){
            alert("Please fill all the fields");
            return;
        }

        const object={name,email,mobileNumber,dob,gender,organization,qualification,designation,specialization,award,image,attachment};

        dispatch(AwardRegistrationed(object)).then(unwrapResult).then((res)=>{
           alert("Award Registration is Successfull");
           window.location.reload();
        }).catch((err)=>{
            console.log(err);
        });
    }

  return (
    <>
  <div className="headedImage">
        <div className="container-fluid col-10">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-3 col-md-12 col-12">
              <img
                src={WDCLogo}
                className="img-fluid register_WDCLogo center-block d-block mx-auto"
              />
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-5 mt-lg-0">
              <p className="para_worldDental text-center">
                Application For Dental Awards
              </p>
            </div>
            <div className="col-lg-3 col-md-12 col-12 mt-5 mt-lg-0">
              <img
                src={WDALogo}
                className="img-fluid register_WDCLogo center-block d-block mx-auto"
              />
            </div>
          </div>
        </div>
      </div>

         <div className='mt-5'>
        <form className='row' onSubmit={handleSubmit}>
            
            <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='text' value={name} className=' input_register' ref={userRef} autoComplete="off" onChange={(e) => setName(e.target.value)} placeholder='Enter Your Name' required
                aria-invalid={validName ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setUserFocus(true)}
                onBlur={() => setUserFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={name && !validName ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter Valid Name
                </p>
              </div>
            </div>
          </div>


  <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='text' value={email} className=' input_register' autoComplete="off" onChange={(e) => setEmail(e.target.value)} placeholder='Enter Your Email' required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={email && !validEmail ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter Valid Email
                </p>
              </div>
            </div>
          </div>


          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='text' value={mobileNumber} className=' input_register' autoComplete="off" onChange={(e) => setMobileNumber(e.target.value)} placeholder='Enter Your Mobile Number' required
                aria-invalid={validMobileNumber ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setMobileFocus(true)}
                onBlur={() => setMobileFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={mobileNumber && !validMobileNumber ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter 10 Digit Valid Mobile Number
                </p>
              </div>
            </div>
          </div>



           <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
            <label className='input_label'>Date of Birth</label>
              <input type='date' value={dob} className=' input_register' autoComplete="off" onChange={(e) => setDob(e.target.value)} placeholder='Enter Your Date Of Birth' required
                aria-invalid={validDob ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setDobFocus(true)}
                onBlur={() => setDobFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={dob && !validDob ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter Valid Date of Birth
                </p>
              </div>
            </div>
          </div>



          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <select value={gender} className=' input_register' onChange={(e) => setGender(e.target.value)} placeholder='Enter Your Gender' required autoComplete='off' aria-invalid={validgender ? "false" : "true"} aria-describedby="uidnote"
                onFocus={() => setGenderFocus(true)}
                onBlur={() => setGenderFocus(false)}>
                <option value={""}>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={gender && !validgender ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter Valid Gender
                </p>
              </div>
            </div>
          </div>




          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='text' value={organization} className=' input_register' autoComplete="off" onChange={(e) => setOrganization(e.target.value)} placeholder={ "Clinic Name / College Name / Hospital Name / Others"} required
                aria-invalid={organization ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setOrganizationFocus(true)}
                onBlur={() => setOrganizationFocus(false)} />

              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={organization && !validOrganization ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Enter Your Clinic Name / College Name / Hospital Name / Others
                </p>
              </div>
            </div>
          </div>  

          
          <div className='col-lg-12 col-12 mb-4'> 
             <div className='input_main center-block d-block mx-auto '>
<Select
                  placeholder="Select a Award..."
                  options={options}
                  isSearchable
                  label={value}
                  className=' input_register2'
                  defaultValue={value}
                  required
                  onChange={setValue}
                />
                 <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={award && !validAward ? "text-danger error_text my-2 instruction" : "offscreen"}>
                  Please Select Your Award
                </p>
              </div>
             </div>
          </div>


  <div className='col-lg-6 col-12 mb-4  d-flex justify-content-end align-items-end'>
            <div className=''>
               <select
                value={qualification}
                onChange={(e) =>setQualification(e.target.value)}  className=' input_register1  ' required
              >
                <option value={""}>Select Qualification</option>
                <option value="BDS">BDS</option>
                <option value="MDS">MDS</option>
              </select>
            </div>
            </div>

            <div className='col-lg-6 col-12 mb-4'>
            <div className='input_main  '>
               <select
                value={designation}
                onChange={(e) =>setDesignation(e.target.value)}
                className=' input_register1  '
              >
                <option value={""}>Select Designation</option>
                <option value="Post Graduate Fellow">Post Graduate Fellow</option>
                <option value="Assistant Professor">Assistant Professor</option>
                <option value="Associate Professor">Associate Professor</option>
                <option value="Professor">Professor</option>
                <option value="Head of Department">Head of Department</option>
                <option value="Principal/Dean">Principal/Dean</option>
                <option value="Private Practitioner">Private Practitioner</option>
                <option value="Consultant">Consultant</option>
              </select>
            </div>
            
          </div>

            <div className='col-lg-6 col-12 mb-4  d-flex justify-content-end align-items-end'>
            <div className='  '>
             <select
                value={specialization}
                onChange={(e) =>setSpecialization(e.target.value)}
                className=' input_register1  '
              >
                <option value={""}>Select Specialization</option>
                <option value="Oral Medicine & Radiology">Oral Medicine & Radiology</option>
                <option value="Oral Pathology">Oral Pathology</option>
                <option value="Public Health Dentistry">Public Health Dentistry</option>
                <option value="Orthodontics">Orthodontics</option>
                <option value="Endodontics">Endodontics</option>
                <option value="Periodontics">Periodontics</option>
                <option value="Pedodontics">Pedodontics</option>
                <option value="Prosthodontics">Prosthodontics</option>
                <option value="Oral & Maxillofacial Surgery">Oral & Maxillofacial Surgery</option>
              </select>
            </div>
            
          </div>


       
          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='file' placeholder='Image' className='image_borderRegister' onChange={handleAvatar} autoComplete="off" accept="image/*" aria-invalid={organization ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setImageFocus(true)}
                onBlur={() => setImageFocus(false)} required />
                 <p className='fileInfo mt-2'><i class="bi bi-info-circle"></i> Upload Your Recent Passport Size Photo Maximum 2MB </p>
              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={image && !validImage ? "text-danger error_text my-2 instruction" : "offscreen"}>
                 Please choose an image.Image size exceeds the maximum limit of 5 MB.
                </p>
              </div>
            </div>
          </div>

       
          <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
              <input type='file' placeholder='Image' className='image_borderRegister' onChange={handleAttachment} autoComplete="off" accept="application/pdf " aria-invalid={organization ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setAttachmentFocus(true)}
                onBlur={() => setAttachmentFocus(false)} required />
 <p className='fileInfo mt-2'><i class="bi bi-info-circle"></i> Upload Your Recent Attachment Maximum 2MB </p>
              <div className='center-block d-block mx-auto'>
                <p id="uidnote" className={attachment && !validAttachment ? "text-danger error_text my-2 instruction" : "offscreen"}>
                 Please choose an attachment.attachment size exceeds the maximum limit of 5 MB.
                </p>
              </div>
            </div>
          </div>
         

         <div className='col-lg-12 col-12 mb-4'>
               <div className='input_main center-block d-block mx-auto '>
                <textarea className='input_register_text' type='text'placeholder='Enter Your Message' value={message} onChange={(e)=>setMessage(e.target.value)} required/>
               </div>  
         </div>

           <div className='col-lg-12 col-12 mb-4'>
            <div className='input_main center-block d-block mx-auto '>
          <button type='submit' className='register_submitbtn'>Submit</button>
</div>


          </div>
        </form>
        </div>
    </>
  )
}

export default AwardRegistration