import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../utils/baseUrl';


export const AbstractRegistered=createAsyncThunk("abstract/registration",async(data,{rejectWithValue})=>{
    try{    
    const res = await axios.post(`${BASE_URL}/abstract/submitAbstract`,data,{headers:{"Content-Type":"multipart/form-data"}});
    return res.data
    }    
    catch(err){
        console.log(err);
        return rejectWithValue(err);
    }
});

export const AbstractGet=createAsyncThunk("abstract/get",async(_,{rejectWithValue})=>{
    try{
       const res=await axios.get(`${BASE_URL}/admin/abstract`,{withCredentials:true});
       return res.data;
    }
    catch(err){
    return rejectWithValue(err);
    }
})

export const getAbstract = createAsyncThunk(
    "admin/abstract",
    async (_, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${BASE_URL}/admin/abstract`, {
          withCredentials: true,
        });
        return res.data;
      } catch (err) {
        console.log(err);
        return rejectWithValue(err);
      }
    }
  );